import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  // Autocomplete,
  // TextField,
  ListItemIcon,
  Radio,
  Backdrop,
  CircularProgress,
  Chip,
} from '@mui/material';
import TreeView from '../../components/TreeView/treeView';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { any, array, bool, func, number, object } from 'prop-types';
import moment from 'moment';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import {
  BUSINESS_ID,
  DIALOG_VENDOR_DETAIL_SCRN__MSG,
  PAGE_DATA_LIMIT,
  SEO_B2B_CAT_TITLE,
  SEO_B2B_CAT_DESC,
  SEO_B2B_CAT_URL,
  SEO_FAV_ICON_URL,
  SEO_B2B_CAT_KEYWORDS,
} from '../../utils/constants';
// import { loading } from '../../store/actions/rootActions';
import { GAEvents, GAPageView } from '../../utils/helper-funtions';
import WebFooter from '../../components/Footer/WebFooter';
// import { setSelectedVCategoryUUID } from '../FLFreelance/redux/actions/fl-business-action';
// import defaultProfileImage from '../../assets/defaultProfileImage.png';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import DefaultLogo from '../../components/StyledAvatar/styledAvatar';
import { Helmet } from 'react-helmet';
const theme = createTheme();
const WebB2BCategoryForm = ({
  categoriesTree,
  selCategory,
  handleCategoryToggle,
  ratingArr,
  sortArr,
  handleRatingToggle,
  selRating,
  localVendorData,
  handleCallNow,
  handleWhatsapp,
  toggleCallBackDrawer,
  selectedVCategory,
  BuisnessType,
  clearFilter,
  handleTerms,
  handlePrivacy,
  openInNewTab,
  // handleShippingDelivery,
  // handleCancellationRefund,
  currentPage,
  handleContactUs,
  handleClickHome,
  handleClickCategory,
  selCity,
  selSort,
  setSelectedSort,
  getVendorList,
  // handleCityToggle,
  filterVCity,
  allVCity,
  setFilterVCity,
  clearSortData,
  clearAllCity,
  clearRatingData,
  pushMessage,
  subCategoryAttributes,
  setSubCategoryTreeAtrributes,
  setFilterParam,
  getSubCategoryVendorList,
  userData,
  isLoading,
  loading,
  setSelectedVCategoryUUID,
  setHasMorePage,
  setCurrentPage,
  // vendors,
  totalPages,
  setTotalPages,
  totalRecords,
  setTotalRecords,
  updateCurrentPage,
  pageNo,
  setPageNo,
  handleToggle,
  selCitySet,
  getSubCategoryVendorCount,
  getCategoryVendorCount
}) => {
  console.log('categoriesTree', categoriesTree);
  const history = useHistory();
  const handleSortChange = (value) => {
    setSelectedSort(value);
  };
  const [searchTerm, setSearchTerm] = useState('');
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickHome}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickCategory}
    >
      {BuisnessType ? 'Business' : 'Freelancer'}
    </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      {selectedVCategory?.title || ''}
    </Link>,
  ];
  const successCallbackCount = (data) => {
    setTotalPages(data?.totalPages);
    setTotalRecords(data?.totalRecords);
    setPageNo(data?.currentPage);
    if (currentPage >= data?.totalPages) {
      setHasMorePage(false);
    } else {
      setHasMorePage(true);
    }
    // loading(false);
  }
  const errorCountCallback = () => {
    setTotalPages(0);
    setTotalRecords(0);
    setPageNo(0);
    setHasMorePage(false);
    loading(false);
  };

  const successParentCallback = () => {
    // setTotalPages(data?.totalPages);
    // setTotalRecords(data?.totalRecords);
    // setPageNo(data?.currentPage);
    if (currentPage >= totalPages) {
      setHasMorePage(false);
    } else {
      setHasMorePage(true);
    }
    loading(false);
  };
  const errorParentCallback = () => {
    setTotalPages(0);
    setTotalRecords(0);
    setPageNo(0);
    setHasMorePage(false);
    loading(false);
  };

  const successCallback = () => {
    // setTotalPages(data?.totalPages);
    // setTotalRecords(data?.totalRecords);
    // setPageNo(data?.currentPage);
    if (currentPage >= totalPages) {
      setHasMorePage(false);
    } else {
      setHasMorePage(true);
    }
    loading(false);
  };
  /* eslint-disable no-unused-vars */
  const errorCallback = (res) => {
    // console.log('errorCallback response', res);
    setTotalPages(0);
    setTotalRecords(0);
    setPageNo(0);
    setHasMorePage(false);
    loading(false);
  };
  /* eslint-enable no-unused-vars */
  useEffect(() => {
    GAPageView(
      '/b2b-category',
      'page_vendor_listing',
      'Web Business Vendor listing'
    );
  }, []);

  useEffect(() => {
    let rParams = setFilterParam();
    const fetchData = async () => {
      getCategoryVendorCount(
        BUSINESS_ID,
        selectedVCategory?.id,
        rParams,
        currentPage,
        successCallbackCount,
        errorCountCallback
      );
      await getVendorList(
        BUSINESS_ID,
        selectedVCategory?.id,
        rParams,
        currentPage,
        successParentCallback,
        errorParentCallback,
        pushMessage
      );
    };
    const fetchSubCategoryData = async () => {
      getSubCategoryVendorCount(
        BUSINESS_ID,
        rParams,
        currentPage,
        successCallbackCount,
        errorCountCallback
      );
      await getSubCategoryVendorList(
        BUSINESS_ID,
        rParams,
        currentPage,
        successCallback,
        errorCallback
      );
    };
    loading(true);
    subCategoryAttributes?.checked.length !== 0
      ? fetchSubCategoryData()
      : fetchData();
    // loading(false);
  }, [
    selSort,
    selRating,
    selCategory,
    selCity,
    subCategoryAttributes?.checked,
    selCitySet,
  ]);

  useEffect(() => {
    setFilterVCity(allVCity);
  }, [allVCity]);

  const handleChangePage = (newPage) => {
    // setPage(newPage);
    updateCurrentPage(newPage);
  };

  const MyListItem = React.memo(({ item, isChecked, onToggle }) => {
    console.log(`Rendering: ${item}`);

    return (
      <div>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => onToggle(item)}
          style={{
            marginRight: '10px',
          }}
        />
        <label className="fs-15 fw-normal text-grey font-gotham">{item}</label>
      </div>
    );
  });
  MyListItem.displayName = 'MyListItem';

  const filteredItems = filterVCity?.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEO_B2B_CAT_TITLE}</title>
        <link rel="canonical" href={SEO_B2B_CAT_URL} />
        <meta name="description" content={SEO_B2B_CAT_DESC} />
        <meta property="og:title" content={SEO_B2B_CAT_TITLE} />
        <meta property="og:description" content={SEO_B2B_CAT_DESC} />
        <meta property="og:url" content={SEO_B2B_CAT_URL} />
        <meta property="og:image" content={SEO_FAV_ICON_URL} />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content={SEO_B2B_CAT_KEYWORDS}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
          {isLoading && (
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '67vh' }}
          >
            <Grid item xs={12}>
              <div className="my-3 ms-2 d-flex align-items-center">
                <ArrowBackIosIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedVCategoryUUID(null);
                    history.goBack();
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <ElynkerLogoComponent>
                    <ElynkerLogo
                      className="ms-3 cursor-pointer"
                      width={'100px'}
                      height={'30px'}
                    />
                  </ElynkerLogoComponent>
                  <div></div>
                </div>
              </div>
              <Divider
                component="div"
                className="main-divider mt-2 mb-3"
                role="presentation"
              />

              <form style={{ marginTop: '20px' }}>
                <Breadcrumbs
                  className="mb-3"
                  separator={
                    <NavigateNextIcon fontSize="small" className="text-gray" />
                  }
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>

                <Card className="category-web-card mb-3">
                  <CardContent className="category-web-content">
                    <div className="d-flex align-items-center flex-content">
                      <Typography
                        variant="h5"
                        className="b2b-category-web-text text-offwhite fw-bold mb-3 fw-bold font-skmodernist"
                        component="div"
                        style={{ width: '50%' }}
                      >
                        Expand your reach by connecting with potential partners
                        and suppliers.
                      </Typography>
                      <div className="">
                        <Button
                          variant="contained"
                          className="post-requirement-btn py-2 px-4"
                          onClick={() => {
                            const userID =
                              userData?.user?.id ||
                              sessionStorage.getItem('random_id');
                            const regId =
                              userData?.registration?.uuid ||
                              sessionStorage.getItem('random_id');
                            const cateName = selectedVCategory?.title;
                            const pageTitle = 'Elynker - Business - ' + cateName;
                            GAEvents(
                              'Request Call Back Banner',
                              'Request Call Back Button',
                              'clicked',
                              {
                                page_title: pageTitle,
                                page_location: window.location.href,
                                page_path: window.location.pathname,
                                category_name: cateName,
                                user_id: userID,
                                reg_uuid: regId,
                                title: 'Request A Call Back Button',
                              }
                            );
                            toggleCallBackDrawer();
                          }}
                        >
                          Request a Call Back
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                >
                  {BuisnessType ? 'Wholesale and Suppliers' : 'Category Name'}
                </Typography>

                <Grid container spacing={2} className="mb-4">
                  <Grid item md={4} lg={4}>
                    <Box
                      sx={{
                        border: '1px solid #e3e3e3 !important',
                        borderRadius: '8px !important',
                        padding: '14px',
                      }}
                    >
                      <Box className="d-flex justify-content-between align-items-center">
                        <Typography className="fs-22 fw-bold font-skmodernsit ">
                          Filters
                        </Typography>
                        <Button onClick={() => {
                            clearFilter();
                            clearRatingData();
                            clearSortData();
                            clearAllCity();
                          }}>
                          <Typography
                            className="fs-15 fw-bold font-skmodernsit text-secondary"
                            onClick={() => {
                              clearFilter();
                              clearRatingData();
                              clearSortData();
                              clearAllCity();
                            }}
                          >
                            Clear All
                          </Typography>
                        </Button>
                      </Box>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Box>
                        <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                          Category
                        </Typography>
                        <List
                          class="filter-web-list"
                          sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            padding: '0px',
                          }}
                        >
                          {/* {categoriesTree.map((category, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                sx={{ paddingLeft: '0px' }}
                                onClick={handleCategoryToggle(category)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    onChange={handleCategoryToggle(category)}
                                    checked={
                                      selCategory.findIndex(
                                        (x) => x.id === category.id
                                      ) !== -1
                                    }
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={category?.title}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })} */}
                          <TreeView
                            nodes={categoriesTree}
                            checked={subCategoryAttributes?.checked}
                            expanded={subCategoryAttributes?.expanded}
                            onCheck={(checked, value) => {
                              // console.log('TreeView', checked, value);
                              setPageNo(1);
                              setCurrentPage(1);
                              setSubCategoryTreeAtrributes({ checked });
                              // setChecked(checked);
                              subCategoryAttributes?.checked?.forEach((id) => {
                                selCategory.findIndex((x) => x.id === id) !== -1;
                              });
                              loading(true);
                              handleCategoryToggle(value);
                            }}
                            onExpand={(expanded) => {
                              setSubCategoryTreeAtrributes({ expanded });
                              handleCategoryToggle(expanded);
                            }}
                          />
                        </List>
                        <Divider
                          component="div"
                          className="my-3"
                          role="presentation"
                        />

                        <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                          Rating
                        </Typography>
                        <List
                          class="filter-web-list"
                          sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                            padding: '0px',
                          }}
                        >
                          {ratingArr.map((rating, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={handleRatingToggle(rating)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      onChange={handleRatingToggle(rating)}
                                      checked={
                                        selRating.findIndex(
                                          (x) => x.label === rating.label
                                        ) !== -1
                                      }
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        paddingTop: '0px !important',
                                        paddingBottom: '0px !important',
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',

                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={rating?.label}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                        <Divider
                          component="div"
                          className="my-3"
                          role="presentation"
                        />
                        <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                          City
                        </Typography>
                        <div className="input-group col-md-12 mb-2">
                          <input
                            id="search"
                            type="text"
                            className="form-control input-lg"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <span className="input-group-btn ms-2">
                            <button
                              className="btn btn-primary btn-primary-lg"
                              type="button"
                              onClick={() => {
                                setSearchTerm('');
                              }}
                              style={{
                                backgroundColor: '#9366f5',
                                border: '0px',
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </span>
                        </div>
                        <Box
                          sx={{
                            maxHeight: '50vh',
                            overflow: 'scroll',
                            overflowX: 'hidden',
                          }}
                        >
                          <div>
                            {filteredItems &&
                              filteredItems?.map((city) => (
                                <MyListItem
                                  key={city}
                                  item={city}
                                  isChecked={selCitySet.has(city)}
                                  onToggle={handleToggle}
                                />
                              ))}
                          </div>
                          {/* <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {filterVCity &&
                          filterVCity?.map((city, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={handleCityToggle(city)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      onChange={handleCityToggle(city)}
                                      checked={selCity.indexOf(city) !== -1}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={city}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List> */}
                        </Box>
                        <Divider
                          component="div"
                          className="my-3"
                          role="presentation"
                        />
                        <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                          Sort By
                        </Typography>
                        <List
                          class="filter-web-sort-list"
                          dense
                          sx={{ width: '100%', bgcolor: 'background.paper' }}
                        >
                          {sortArr &&
                            sortArr?.map((option, index) => {
                              const labelId = `radio-list-secondary-label-${index}`;
                              return (
                                <ListItem key={index} disablePadding>
                                  <ListItemButton
                                    sx={{ padding: '3px 0px' }}
                                    onClick={() => handleSortChange(option)}
                                  >
                                    <ListItemIcon>
                                      <Radio
                                        edge="end"
                                        onChange={() => handleSortChange(option)}
                                        checked={selSort?.label === option?.label} //selRating.findIndex(x => x.label === rating.label) !== -1
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                        sx={{
                                          color: '#D4D4D4',
                                          '&.Mui-checked': {
                                            color: '#416AD4',
                                          },
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      id={labelId}
                                      className="fs-15 fw-normal text-grey font-gotham"
                                      primary={option.label}
                                      sx={{margin: '0px'}}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                        </List>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={8} lg={8}>
                    <div
                      className="mb-4 b2b-business"
                      style={{ display: 'none' }}
                    >
                      <Stack
                        direction="row"
                        spacing={1.5}
                        useFlexGap
                        flexWrap="wrap"
                      >
                        {/* <Chip
                        ref={nearRef}
                        label="Near Me"
                        clickable
                        sx={{ display: 'none' }}
                      /> */}
                      </Stack>
                    </div>
                    <div>
                      <CustomPagination
                        page={pageNo}
                        numPages={totalPages}
                        count={totalRecords}
                        rowsPerPage={PAGE_DATA_LIMIT}
                        onPageChange={handleChangePage}
                      />
                    </div>
                    <List className="pt-0">
                      {Array.isArray(localVendorData)
                        ? localVendorData.map((item, index) => {
                          const lastUpdate = moment(
                            item?.last_login
                          )?.fromNow();
                          const l2Category = item.l2_categories;
                          console.log('l2Category here is', l2Category);
                          return (
                            <ListItem
                              className="b2b-list-item"
                              ref={null}
                              key={index}
                              style={
                                item?.status === 'approved'
                                  ? { cursor: 'pointer' }
                                  : {}
                              }
                            >
                              {/* Vendor card content */}
                              <Card
                                className="card"
                                onClick={() => {
                                  if (
                                    item?.uuid &&
                                    item?.status === 'approved'
                                  ) {
                                    history.push(`/b2b-detail/${item.uuid}`);
                                  } else {
                                    pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                                  }
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  {item?.image_path ? (
                                    <CardMedia
                                      className="category-media"
                                      image={
                                        item?.image_path
                                          ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                          : ''
                                      }
                                      sx={{
                                        padding: '1em 1em 0 1em',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  ) : (
                                    <DefaultLogo
                                      name={item?.company_name || item?.name}
                                      showEdit={false}
                                    />
                                  )}

                                  <CardContent className="content p-0">
                                    <div className="d-flex justify-content-between align-items-start w-100">
                                      <Typography
                                        variant="h5"
                                        className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                        component="div"
                                      >
                                        {item?.company_name || ''}
                                      </Typography>
                                      <div>
                                        {item?.status === 'approved' && (
                                          <RightArrowIcon />
                                        )}
                                      </div>
                                    </div>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-grey mb-1 font-gotham"
                                    >
                                      {item?.business_description || ''}
                                    </Typography>
                                    <Stack
                                      sx={{ marginBottom: '7px' }}
                                      direction="row"
                                      spacing={1}
                                    >
                                      {l2Category &&
                                        l2Category.length > 0 &&
                                        l2Category.map((category, index) => (
                                          <Chip
                                            key={index}
                                            className="mr-2"
                                            label={category}
                                            variant="outlined"
                                            color="primary"
                                          />
                                        ))}
                                    </Stack>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-gotham"
                                    >
                                      City : {item?.city}
                                    </Typography>
                                    <Box>
                                      <Rating
                                        name="read-only"
                                        value={item?.rating}
                                        size="small"
                                        precision={0.1}
                                        readOnly
                                      />
                                    </Box>
                                  </CardContent>
                                </div>
                                {item?.status === 'approved' && (
                                  <CardContent className="p-0 mt-1">
                                    <Box className="d-flex justify-content-between">
                                      <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-1">
                                          <CircleIcon
                                            style={{
                                              color: '#1843A0',
                                              width: '7px',
                                              height: '7px',
                                              marginRight: '10px',
                                            }}
                                          />
                                          <Typography
                                            variant="body2"
                                            className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                          >
                                            Last seen{' '}
                                            {lastUpdate !== 'Invalid date'
                                              ? lastUpdate
                                              : ''}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                        >
                                          Members
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-secondary mb-1 font-skmodernist"
                                        >
                                          {item?.member_count || 0}
                                        </Typography>
                                      </div>
                                      <div className="d-flex flex-column">
                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                        >
                                          Products
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-secondary mb-1 font-skmodernist"
                                        >
                                          {item?.product_count || 0}
                                        </Typography>
                                      </div>
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        className="fs-16 fw-bold text-secondary mb-1 font-skmodernist"
                                      >
                                        {item?.status !== 'approved'
                                          ? 'Un-Verified'
                                          : ''}
                                      </Typography>
                                    </Box>
                                  </CardContent>
                                )}
                                {item?.status === 'approved' && (
                                  <>
                                    <Divider
                                      component="div"
                                      className="mt-2 mb-3"
                                      role="presentation"
                                    />
                                    <CardContent className="p-0 d-flex justify-content-between">
                                      <Button
                                        variant="contained"
                                        className="call-now-btn"
                                        onClick={(e) => {
                                          const mobileNo = item?.mobile_number
                                            ? '<mobile_no>'
                                            : '';
                                          GAEvents(
                                            'Call_button',
                                            'Call button',
                                            'clicked',
                                            {
                                              uuid: item?.uuid || '',
                                              company_name:
                                                item?.company_name || '',
                                              vendor_id: item?.id,
                                              vendor_uuid: item?.uuid || '',
                                              phone: mobileNo,
                                            }
                                          );
                                          handleCallNow(
                                            e,
                                            item?.mobile_number,
                                            item?.country_code || '',
                                            item?.id
                                          );
                                        }}
                                        disabled={item?.status !== 'approved'}
                                      >
                                        <CallNowIcon className="me-2" />
                                        Call Now
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="whatsapp-btn"
                                        onClick={(e) => {
                                          const whatsappNo =
                                            item?.whatsapp_number
                                              ? '<whatsapp_no>'
                                              : '';
                                          GAEvents(
                                            'Whatsapp_chat_button',
                                            'Whatsapp chat button',
                                            'clicked',
                                            {
                                              uuid: item?.uuid || '',
                                              company_name:
                                                item?.company_name || '',
                                              vendor_id: item?.id,
                                              vendor_uuid: item?.uuid || '',
                                              whatsapp: whatsappNo,
                                            }
                                          );
                                          handleWhatsapp(
                                            e,
                                            item?.whatsapp_number,
                                            item?.country_code || '',
                                            item?.id
                                          );
                                        }}
                                        disabled={item?.status !== 'approved'}
                                      >
                                        <WhatsappIcon className="me-2" />
                                        WhatsApp
                                      </Button>
                                    </CardContent>
                                  </>
                                )}
                              </Card>
                            </ListItem>
                          );
                        })
                        : []}
                    </List>
                    <div>
                      <CustomPagination
                        page={pageNo}
                        numPages={totalPages}
                        count={totalRecords}
                        rowsPerPage={PAGE_DATA_LIMIT}
                        onPageChange={handleChangePage}
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
              <div className="homepage-footer" style={{ marginTop: '100px' }}>
                <WebFooter
                  handleTerms={handleTerms}
                  handlePrivacy={handlePrivacy}
                  openInNewTab={openInNewTab}
                  handleContactUs={handleContactUs}
                  userData={userData}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
};
WebB2BCategoryForm.propTypes = {
  nearRef: any,
  isLoading: any,
  SearchComponent: any,
  setIsCallBackDrawerOpen: any,
  categoriesTree: any,
  selCategory: any,
  handleCategoryToggle: any,
  clearRatingData: any,
  handleRatingAndCategoryFilter: any,
  ratingArr: any,
  sortArr: any,
  handleRatingToggle: any,
  selRating: any,
  vendors: any,
  handleCallNow: any,
  handleWhatsapp: any,
  getVendorDetails: any,
  clearFilter: any,
  handleTerms: any,
  handlePrivacy: any,
  openInNewTab: func,
  toggleCallBackDrawer: any,
  selectedVCategory: any,
  BuisnessType: any,
  handleShippingDelivery: func,
  handleCancellationRefund: func,
  handleContactUs: func,
  handleClickHome: func,
  handleClickCategory: func,
  selCity: any,
  setSelCity: func,
  markNearByClick: bool,
  nearByCity: any,
  setSelRating: any,
  setSelCategory: any,
  selSort: any,
  setSelectedSort: any,
  getVendorList: any,
  handleCityToggle: func,
  filterVCity: array,
  setFilterVCity: func,
  allVCity: array,
  clearSortData: func,
  clearAllCity: func,
  pushMessage: func,
  subCategoryAttributes: any,
  setSubCategoryTreeAtrributes: func,
  setFilterParam: func,
  getSubCategoryVendorList: func,
  userData: object,
  loading: func,
  setSelectedVCategoryUUID: func,
  localVendorData: any,
  currentPage: any,
  setHasMorePage: func,
  setCurrentPage: func,
  totalPages: number,
  setTotalPages: func,
  totalRecords: number,
  setTotalRecords: func,
  updateCurrentPage: func,
  pageNo: number,
  setPageNo: func,
  handleToggle: func,
  item: any,
  isChecked: any,
  onToggle: any,
  selCitySet: any,
  getSubCategoryVendorCount: func,
  getCategoryVendorCount: func
};
export default WebB2BCategoryForm;
