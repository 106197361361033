export const SAVE_PRODUCT_IMAGES = 'SAVE_PRODUCT_IMAGES';
export const SAVE_CATALOG_PRODUCT = 'SAVE_PRODUCT_CATALOG';
export const GET_PRODUCTS_DATA = 'GET_PRODUCTS_DATA';
export const SAVE_PRODUCT_DATA = 'SAVE_PRODUCT_DATA';
export const PRODUCT_DESC = 'PRODUCT_DESC';
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const PRODUCT_BUDGET = 'PRODUCT_BUDGET';
export const PRODUCT_MOQ = 'PRODUCT_MOQ';
export const PRODUCT_SUB_CATEGORY = 'PRODUCT_SUB_CATEGORY';
export const SET_PRODUCT_UPLOAD_PROGRESS = 'SET_PRODUCT_UPLOAD_PROGRESS';
export const SINGLE_PRODUCT = 'SINGLE_PRODUCT';
export const PAGE_ACTION_TYPE = 'PAGE_ACTION_TYPE';
export const RESET_CATALOG_DATA = 'RESET_CATALOG_DATA';
export const REMOVE_PROD_IMAGE = 'REMOVE_PROD_IMAGE';
export const PRODUCT_UNIT = 'PRODUCT_UNIT';
export const SET_DELETE_PROD_IMAGE = 'SET_DELETE_PROD_IMAGE';
export const GET_ALL_UNIT = 'GET_ALL_UNIT';
export const IS_PRICE_HIDDEN = 'IS_PRICE_HIDDEN';
