import {
  LOADING,
  SAVE_MOBILE_NO,
  SAVE_COUNTRY_CODE,
  SAVE_COUNTRY_CODE_NAME,
  RESET_LOGIN_DATA,
  GET_TEMPLATE_PAGE_DATA,
  GET_OTP_ENABLED_DATA
} from '../types';

const INITIAL_STATE = {
  mobile_number: '',
  country_code: '+91',
  loading: false,
  error: '',
  country_code_name: 'IN',
  all_template: [],
  isOtpEnabled: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
        error: '',
      };
    case SAVE_MOBILE_NO:
      return { ...state, mobile_number: action.payload };

    case SAVE_COUNTRY_CODE:
      return { ...state, country_code: action.payload };

    case SAVE_COUNTRY_CODE_NAME:
      return { ...state, country_code_name: action.payload };

    case RESET_LOGIN_DATA:
      return INITIAL_STATE;

    case GET_TEMPLATE_PAGE_DATA:
      return {
        ...state,
        all_template: action.payload
      }
    case GET_OTP_ENABLED_DATA:
      return {
        ...state,
        isOtpEnabled: action.payload
      }
    default:
      return state;
  }
};
