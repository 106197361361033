/* eslint-disable no-undef */
import React, { useState, useRef, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  Badge,
  ListItemText,
  Accordion,
  AccordionSummary,
  ListItemIcon,
  AccordionDetails,
  Chip,
  Stack,
  TextareaAutosize,
  FormControl,
  InputLabel,
  CardMedia,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
// import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import ProductCloseIcon from '../../assets/product-close-icon.svg';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import Checkbox from '@mui/material/Checkbox';
// import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';
// import { ReactComponent as SellerProfileIcon } from '../../assets/seller-profile-icon.svg';
// import { TabPanel } from '@mui/lab';
import Rating from '@mui/material/Rating';
import {
  getFreelancerVendorDetails,
  getVendorDetails,
  postMemberContacted,
} from '../B2BCategory/redux/actions/b2b-category-action';
import { updateVendorUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as SkillsIcon } from '../../assets/skills-blue-icon.svg';
import { ReactComponent as PortfolioIcon } from '../../assets/portfolio-blue-icon.svg';
import { ReactComponent as CertificationsIcon } from '../../assets/certifications-blue-icon.svg';
// import { ReactComponent as BadIcon } from '../../assets/bad-emoji-icon.svg';
// import { ReactComponent as AverageIcon } from '../../assets/average-emoji-icon.svg';
// import { ReactComponent as GoodIcon } from '../../assets/good-emoji-icon.svg';
// import { ReactComponent as CategoryMediaIcon } from '../../assets/category-media-icon.svg';
// import AddBtnIcon from '../../assets/add-btn-icon.svg';
// import { ReactComponent as ProductImg } from '../../assets/product-image.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { styled } from '@mui/system';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from '@mui/styles';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { ListItemAvatar, Avatar } from '@mui/material';
import {
  addFeedback,
  saveFeedback,
  removeFeedbackImages,
  clearFeedBackFormData,
} from '../B2BDetail/redux/actions/b2bDetailActions';
import { ConfirmationDialog } from '../../components/ActionPopup';
// import CourtneyImg from '../../assets/courtney-profile.jpg';
import { connect } from 'react-redux';
import {
  COMPANY_LOGO_BASE_URL,
  COVER_IMAGE_BASE_URL,
  PRODUCT_IMAGE_BASE_URL,
  RESUME_BASE_URL,
  UPLOAD_REVIEW_IMAGE,
  WHATSAPP_URL,
} from '../../utils/APIs/api';
import ImageDialog from '../../components/ImageOverlay';
import moment from 'moment';
import { hasAnyErrorPresent } from '../../utils/validation';
import Resizer from 'react-image-file-resizer';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import useValidation from '../../hooks/useValidation';
import Responsive from '../../utils/responsive';
import { useMediaQuery } from 'react-responsive';
import ConnectWithModal from './Connect-With-Modal';
import { saveAs } from 'file-saver';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CLICKED, DIALOG_NOT_LOGIN_MSG, DIALOG_TITLE, FREELANCER_DETAIL_TITLE, LOAD } from '../../utils/constants';
import { GAEvents } from '../../utils/helper-funtions';

function findMinimumBudget(data) {
  if (data.length === 0) return null; // Handle empty array case
  let minBudget = parseFloat(data[0].budget);
  for (let i = 1; i < data.length; i++) {
    const budget = parseFloat(data[i].budget);
    if (budget < minBudget) {
      minBudget = budget;
    }
  }
  return minBudget;
}
// const useStyles = makeStyles(() => ({
//     clickableEmoji: {
//         '&:hover, &:active': {
//             padding: '6px',
//             border: `2px solid #864FFD`,
//             borderRadius: '50%',
//         },
//     },
// }));

const useStyles = makeStyles(() => ({
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
  },
}));

const theme = createTheme();

const FreelancerDetailForm = ({
  cover_image,
  // company_name,
  about_company,
  // created_by,
  products,
  // social_links,
  // address1,
  // address2,
  whatsapp_number,
  reviews,
  reviewStarsPercentages,
  // freelancer_banner_projects,
  freelancer_resumes,
  certificates,
  contacted_members,
  image_path,
  // id,
  addFeedback,
  freelancer_skills,
  // rating_member_count,
  rating,
  categories,
  language,
  id,
  postMemberContacted,
  pushMessage,
  localFlDetails,
  saveFeedback,
  updateVendorUserData,
  user,
  userData,
  name,
  freelancer_role,
  clearFeedBackFormData,
  removeFeedbackImages,
  // additional_detail1,
  // additional_detail2,
  // additional_detail3,
  vendorId,
  userId,
  getFreelancerVendorDetails,
  getVendorDetails,
}) => {
  const { feedback } = localFlDetails;

  console.log('====feedback567', feedback);

  // console.log("====freelancer_skills", freelancer_skills);
  const { vendorId: vendorsId } = useParams();
  const history = useHistory();
  const { errors, validateInput, setErrors } = useValidation();
  const inputRef = useRef(null);
  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categories[0]?.title || ''
  );
  const [allproducts, setAllProducts] = useState(products);

  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = React.useState();

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const toggleCallBackDrawer = () => {
    setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
  };

  // React.useEffect(()=>{
  // },[
  //   freelancer_skills, certificates, language, freelancer_resumes
  // ])
  React.useEffect(() => {
    GAEvents(FREELANCER_DETAIL_TITLE, "Freelancer Detail", LOAD, {
			page_title: 'Freelancer Detail',
			page_location: window.location.href,
			page_path: window.location.pathname,
			user_id: userData?.id || sessionStorage.getItem('random_id'),
			reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id')
		});
    setAllProducts(products);
  }, []);

  const changeHandler = async (e) => {
    const files = e.target.files;
    const currentImages = feedback?.images ? Array.from(feedback.images) : [];

    if (currentImages.length + files.length > 5) {
      pushMessage('You can upload up to 5 photos.');
      e.preventDefault();
      return;
    }

    console.log('files', files, feedback?.images);

    let compressedImages = await compressImages(files);
    addFeedback({ image: compressedImages });
    e.target.value = null;
  };
  const compressImages = (files) => {
    return Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // New width
            500, // New height
            'JPEG', // Format
            75, // Quality
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'file'
          );
        });
      })
    );
  };
  const [isFeedBackDrawerOpen, setIsFeedBackDrawerOpen] = useState(false);
  const toggleFeedBackDrawer = () => {
    if (!isFeedBackDrawerOpen) {
      // clear feedback data
      clearFeedBackFormData();
    }
    setIsFeedBackDrawerOpen(!isFeedBackDrawerOpen);
    setErrors(null);
    addFeedback({ feedbackRating: 0, feedbackDetail: null, images: null });
    // setRatingValue(0);
  };

  //     const [value, setValue] = React.useState('one');

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  React.useEffect(() => {
    console.log('====allproducts', allproducts);
  }, [allproducts]);
  const handleChange = (event, newValue) => {
    console.log('tab change handleChange', newValue);
    setValue(newValue);
    if (newValue === 1) {
      setSelectedCategoryId('All Category');
      setAllProducts(products);
    }
  };
  const handleCategoryChange = (event) => {
    console.log(
      '===handleCategoryChange event.target.value',
      event.target.value
    );
    // setSelectedCategoryId(event.target.value)
  };
  const handleImageClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className="b2b-detail-tabpanel"
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3, px: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleWhatsappCall = () => {
    if (userData?.registration?.id !== undefined) {
      if (id && whatsapp_number) {
        postMemberContacted(id, whatsapp_number);
        const whatsappUrl = `${WHATSAPP_URL}${user?.country_code}${whatsapp_number}`;
        console.log('WhatApp url data: ', whatsappUrl);
        window.open(whatsappUrl, '_blank');
      } else {
        pushMessage('WhatsApp number not found.');
      }
    } else {
      handleOpenCallModal();
    }
    handleCloseModal();
  };
  const handleCall = () => {
    console.log('===handleCall', whatsapp_number);
    if (userData?.registration?.id !== undefined) {
      if (user?.mobile_number !== null && user?.mobile_number !== '') {
        postMemberContacted(id, whatsapp_number);
        window.location.href = `tel:${user?.country_code}${user?.mobile_number}`;
      } else {
        pushMessage('Phone number not found.');
      }
    } else {
      handleOpenCallModal();
    }
    handleCloseModal();
  };
  const [viewImagedialogOpen, setViewImagedialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleViewImageClick = (image) => {
    console.log('===handleViewImageClick', image);
    setSelectedImage(image);
    setViewImagedialogOpen(true);
  };
  const handleViewImageCloseDialog = () => {
    setViewImagedialogOpen(false);
    setSelectedImage('');
  };
  const onFeedbackSubmit = async () => {
    console.log('====onFeedbackSubmit feedback', feedback);
    // if (
    //   !feedback?.feedbackRating ||
    //   !feedback?.feedbackDetail ||
    //   !feedback?.images?.length
    // ) {
    if (!feedback?.feedbackRating) {
      validateInput('feedbackRating', '');
      // return;
    }
    // if (!feedback?.feedbackDetail) {
    //   validateInput("feedbackDetail", "");
    //   // return;
    // }
    // if (!feedback?.images?.length) {
    //   validateInput("photos", []);
    //   // return;
    // }
    // }
    else {
      console.log('===handleSubmit Add catalog errors', errors);
      if (!hasAnyErrorPresent(errors)) {
        const res = await saveFeedback(userData, feedback, id);
        pushMessage(res);
        setIsFeedBackDrawerOpen(false);
        addFeedback([]);
        updateVendorUserData(user, { rating: feedback.feedbackRating }, id);
        getFreelancerVendorDetails(id);
        addFeedback({ feedbackRating: 0, feedbackDetail: null, images: null });
        setErrors(null);
      }
    }
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const deleteProdImage = (index) => {
    console.log('===deleteProdImage', index);
    removeFeedbackImages(index);
  };

  const [value, setValue] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  // const [ratingValue, setRatingValue] = React.useState(3);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.mode === 'light' ? '#f5f5f6' : '#f5f5f6',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#864FFD' : '#864FFD',
    },
  }));

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onChangeFeedbackDetails = (event) => {
    const value = event.target.value;
    validateInput('feedbackDetail', value);
    addFeedback({ feedbackDetail: value });
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const classes = useStyles();

  useEffect(() => {
    console.log('Param vendorId', vendorsId);

    getFreelancerVendorDetails(vendorsId);
    getVendorDetails(vendorsId);
  }, [vendorsId]);

  const handleConfirm = () => {
    history.push('/login');
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <ConfirmationDialog
          open={openCallModal}
          handleClose={handleCloseCallModal}
          title={DIALOG_TITLE}
          content={DIALOG_NOT_LOGIN_MSG}
          confirmText="Yes"
          cancelText="No"
          onConfirm={handleConfirm}
        />

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon onClick={() => history.goBack()} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mt-2"
              role="presentation"
            />
            <Box sx={{ margin: '16px -16px 0px -16px' }}>
              <CardMedia
                className="profile-background-2"
                style={{ backgroundPosition: 'center' }}
                onClick={() =>{
                  GAEvents(FREELANCER_DETAIL_TITLE, "Profile Detail", CLICKED, {
                    userId: userData?.id || sessionStorage.getItem('random_id'),
                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                    title: "Add Resume"
                  });
                  handleViewImageClick(
                    cover_image
                      ? COVER_IMAGE_BASE_URL + cover_image
                      : require('../../assets/JobSeeker-PDP-Header2340x400px.png')
                  );
                }}
                image={
                  cover_image
                    ? COVER_IMAGE_BASE_URL + cover_image
                    : require('../../assets/JobSeeker-PDP-Header2340x400px.png')
                }
              >
                <div className="profile-background-2-tint" />
              </CardMedia>
              {/* <B2BDetailBanner src={COVER_IMAGE_BASE_URL +cover_image} width={'100%'} /> */}
            </Box>

            <form
              style={{ marginTop: '50px' }}
              className="freelancer-detail-form"
            >
              {Responsive(
                <Box className="freelancer-detail-card">
                  <Card className="card">
                    <div className="d-flex justify-content-between">
                      <CardMedia>
                        <img
                          alt="image"
                          loading="lazy"
                          className="category-media"
                          onClick={() => {
                            GAEvents(FREELANCER_DETAIL_TITLE, "Profile Detail", CLICKED, {
                              userId: userData?.id || sessionStorage.getItem('random_id'),
                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                              title: "Cover Image"
                            });
                            handleViewImageClick(
                              image_path
                                ? COMPANY_LOGO_BASE_URL + image_path
                                : require('../../assets/profile_cover_bg.jpg')
                            )
                          }}
                          src={
                            image_path
                              ? COMPANY_LOGO_BASE_URL + image_path
                              : require('../../assets/profile_cover_bg.jpg')
                          }
                        />
                      </CardMedia>
                      <CardContent className="content p-0">
                        <div className="d-flex justify-content-between align-items-start w-100">
                          <Typography
                            variant="h5"
                            className="fs-18 text-darkgrey mb-1 fw-medium font-skmodernist"
                            component="div"
                          >
                            {name}
                          </Typography>
                          <div className="d-flex align-items-center">
                            <IconButton className="p-1">
                              <RatingIcon />
                            </IconButton>
                            <Typography
                              variant="h5"
                              className="fs-13 text-darkgrey fw-normal  font-gotham"
                              component="div"
                            >
                              {parseFloat(rating)?.toFixed(1) || 0}
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          variant="body2"
                          className="fs-13 text-primary-2 mb-1 font-skmodernist"
                        >
                          {freelancer_role}
                        </Typography>
                      </CardContent>
                    </div>
                    <Divider
                      component="div"
                      className="mt-2 mb-2"
                      role="presentation"
                    />
                    <CardContent className="p-0 d-flex mt-1">
                      <div className="d-flex flex-column me-5">
                        <Typography
                          variant="body2"
                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                        >
                          Members
                        </Typography>
                        <Typography
                          variant="body2"
                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                        >
                          {contacted_members}
                        </Typography>
                      </div>
                      <div className="d-flex flex-column">
                        <Typography
                          variant="body2"
                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                        >
                          Service
                        </Typography>
                        <Typography
                          variant="body2"
                          className="fs-13 text-darkgrey mb-1 font-skmodernist"
                        >
                          {allproducts?.length}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Box>,
                <Box className="freelancer-detail-web-card">
                  <Card className="card">
                    <div className="d-flex justify-content-between">
                      <CardMedia>
                        <img
                          alt="image"
                          loading="lazy"
                          className="category-media"
                          onClick={() =>{
                            GAEvents(FREELANCER_DETAIL_TITLE, "Profile Detail", CLICKED, {
                              userId: userData?.id || sessionStorage.getItem('random_id'),
                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                              title: "Cover Image"
                            });
                            handleViewImageClick(
                              image_path
                                ? COMPANY_LOGO_BASE_URL + image_path
                                : require('../../assets/profile_cover_bg.jpg')
                            )
                          }}
                          src={
                            image_path
                              ? COMPANY_LOGO_BASE_URL + image_path
                              : require('../../assets/profile_cover_bg.jpg')
                          }
                        />
                      </CardMedia>
                      <CardContent className="content p-0">
                        <div className="p-0 d-flex mt-1 web-card-gap mb-1">
                          <div className="d-flex flex-column">
                            <Typography
                              variant="h5"
                              className="fs-18 text-darkgrey mb-1 fw-medium font-skmodernist"
                              component="div"
                            >
                              {name}
                            </Typography>

                            <Typography
                              variant="body2"
                              className="fs-14 text-primary-2 mb-1 font-skmodernist"
                            >
                              {freelancer_role}
                            </Typography>
                            <div className="d-flex align-items-center">
                              <IconButton className="p-1">
                                <RatingIcon />
                              </IconButton>
                              <Typography
                                variant="h5"
                                className="fs-13 text-darkgrey fw-normal  font-gotham"
                                component="div"
                              >
                                {parseFloat(rating)?.toFixed(1) || 0}
                              </Typography>
                            </div>
                          </div>

                          <div className="d-flex flex-column me-5">
                            <Typography
                              variant="body2"
                              className="fs-13 text-darkgrey mb-1 font-skmodernist"
                            >
                              Members
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-13 text-darkgrey mb-1 font-skmodernist"
                            >
                              {contacted_members}
                            </Typography>
                          </div>

                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2"
                              className="fs-13 text-darkgrey mb-1 font-skmodernist"
                            >
                              Service
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-13 text-darkgrey mb-1 font-skmodernist"
                            >
                              {allproducts?.length}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                </Box>
              )}

              <Divider component="div" className="my-3" role="presentation" />
              <Box>
                <Typography
                  variant="h5"
                  className="fs-20 fs-md-32 fs-md-32 mb-3 fw-bold font-skmodernist"
                  gutterBottom
                >
                  Top Services
                </Typography>
                <div className="mb-3 top-products">
                  <Stack
                    direction="row"
                    spacing={1.5}
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {categories &&
                      categories.map((p, i) => (
                        <Chip key={i} label={p?.title} />
                      ))}
                  </Stack>
                </div>
              </Box>
              <Box>
                <List>
                  {Responsive(
                    <ListItem className="b2b-list-item">
                      <Card className="card">
                        <CardContent className="p-0 d-flex justify-content-between mt-1">
                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2"
                              className="fs-14 text-gray mb-0 font-skmodernist"
                            >
                              Starting at
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-20 fw-bold text-secondary mb-1 font-skmodernist"
                            >
                              ₹ {findMinimumBudget(products)?.toFixed(2)}/{' '}
                              <span className="fs-14 text-lightgrey fw-normal">
                                Hour
                              </span>
                            </Typography>
                          </div>
                        </CardContent>
                        <Divider
                          component="div"
                          className="mt-2 mb-3"
                          role="presentation"
                        />
                        <CardContent className="p-0 d-flex justify-content-between">
                          <Button
                            variant="contained"
                            className="call-now-btn "
                            onClick={()=>{
                              GAEvents(FREELANCER_DETAIL_TITLE, "Call Now", CLICKED, {
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "Call Now",
                                phone: user?.mobile_number ? '<mobile_no>' : ''
                              });
                              handleCall();
                            }}
                          >
                            <CallNowIcon className="me-2" />
                            Call Now
                          </Button>
                          <Button
                            variant="contained"
                            className="whatsapp-btn "
                            onClick={()=>{
                              GAEvents(FREELANCER_DETAIL_TITLE, "Whatsapp", CLICKED, {
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "Whatsapp",
                                phone: whatsapp_number ? '<whatsapp_no>' : ''
                              });
                              handleWhatsappCall();}}
                            rel="noopener noreferrer"
                          >
                            <WhatsappIcon className="me-2" />
                            WhatsApp
                          </Button>
                        </CardContent>
                      </Card>
                    </ListItem>,
                    <ListItem className="b2b-list-detail-web-item">
                      <Card className="card">
                        <CardContent className="p-0 d-flex justify-content-between align-items-center mt-1">
                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2"
                              className="fs-14 text-gray mb-0 font-skmodernist"
                            >
                              Starting at
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-20 fw-bold text-secondary mb-1 font-skmodernist"
                            >
                              ₹ {findMinimumBudget(products)?.toFixed(2)}/{' '}
                              <span className="fs-14 text-lightgrey fw-normal">
                                Hour
                              </span>
                            </Typography>
                          </div>
                          <Box
                            className="p-0 d-flex justify-content-between"
                            columnGap={2}
                          >
                            <Button
                              variant="contained"
                              className="call-now-web-btn "
                              onClick={()=>{
                                GAEvents(FREELANCER_DETAIL_TITLE, "Call Now", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: "Call Now",
                                  phone: user?.mobile_number ? '<mobile_no>' : ''
                                });
                                handleCall();
                              }}
                            >
                              <CallNowIcon className="me-2" />
                              Call Now
                            </Button>
                            <Button
                              variant="contained"
                              className="whatsapp-web-btn "
                              onClick={()=>{
                                GAEvents(FREELANCER_DETAIL_TITLE, "Whatsapp", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: "Whatsapp",
                                  phone: whatsapp_number ? '<whatsapp_no>' : ''
                                });
                                handleWhatsappCall();
                              }}
                              rel="noopener noreferrer"
                            >
                              <WhatsappIcon className="me-2" />
                              WhatsApp
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </ListItem>
                  )}
                </List>
              </Box>

              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                  className="b2b-detail-tabs"
                >
                  <Tab
                    label="Overview"
                    className="fs-15 font-skmodernist text-capitalize"
                    {...a11yProps(0)}
                  />
                  {/* <Tab label="Catalog"  className="fs-15 font-skmodernist text-capitalize" {...a11yProps(1)} /> */}

                  <Tab
                    label={
                      <React.Fragment>
                        <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                          Service
                        </span>
                        <Badge
                          badgeContent={products?.length}
                          color="error"
                          className="b2b-detail-tabs-badge"
                        />{' '}
                        {/* Adjust badge content and color as needed */}
                      </React.Fragment>
                    }
                    {...a11yProps(1)}
                  />

                  <Tab
                    label={
                      <React.Fragment>
                        <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                          Reviews
                        </span>
                        {
                          <Badge
                            badgeContent={reviews?.length}
                            color="error"
                            className="b2b-detail-tabs-badge"
                          />
                        }
                      </React.Fragment>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <Typography className="fs-14 font-gotham text-gray mb-3 text-justify">
                    {about_company || ''}
                  </Typography>
                  <div className="freelancer-detail-accordion">
                    <Accordion
                      defaultExpanded={
                        freelancer_skills?.length > 0 ? true : false
                      }
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: '#416AD4',
                            }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 fontskmodernist text-darkgrey"
                      >
                        <ListItemIcon className="align-items-center me-2">
                          <SkillsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Skills" />
                      </AccordionSummary>
                      <AccordionDetails>
                        {freelancer_skills &&
                          freelancer_skills.map((r) => (
                            <>
                              <div className="d-flex mb-3">
                                <ListItemIcon className="me-2 mt-1">
                                  <CircleIcon
                                    style={{
                                      color: '#1843A0',
                                      width: '7px',
                                      height: '13px',
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography className="fs-14 text-gray font-gotham">
                                  {r}
                                </Typography>
                              </div>
                            </>
                          ))}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      defaultExpanded={certificates?.length > 0 ? true : false}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: '#416AD4',
                            }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 fontskmodernist text-darkgrey"
                      >
                        <ListItemIcon className="align-items-center me-2">
                          <CertificationsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Certifications" />
                      </AccordionSummary>
                      <AccordionDetails>
                        {certificates &&
                          certificates.map((c, k) => (
                            <>
                              <div className="d-flex mb-3" key={k}>
                                <ListItemIcon className="me-2 mt-1">
                                  <CircleIcon
                                    style={{
                                      color: '#1843A0',
                                      width: '7px',
                                      height: '13px',
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography className="fs-14 text-gray font-gotham">
                                  {c.description}
                                </Typography>
                              </div>
                            </>
                          ))}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      defaultExpanded={
                        language && language != '' ? true : false
                      }
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: '#416AD4',
                            }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 fontskmodernist text-darkgrey"
                      >
                        <ListItemIcon className="align-items-center me-2">
                          <SkillsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Language" />
                      </AccordionSummary>
                      <AccordionDetails>
                        {language &&
                          language.split(',').map((l, k) => (
                            <>
                              <div className="d-flex mb-3" key={k}>
                                <ListItemIcon className="me-2 mt-1">
                                  <CircleIcon
                                    style={{
                                      color: '#1843A0',
                                      width: '7px',
                                      height: '13px',
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography className="fs-14 text-gray font-gotham">
                                  {l}
                                </Typography>
                              </div>
                            </>
                          ))}
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      defaultExpanded={
                        freelancer_resumes?.length > 0 ? true : false
                      }
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: '#416AD4',
                            }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 fontskmodernist text-darkgrey"
                      >
                        <ListItemIcon className="align-items-center me-2">
                          <PortfolioIcon />
                        </ListItemIcon>
                        <ListItemText primary="Resume" />
                      </AccordionSummary>
                      <AccordionDetails>
                        {freelancer_resumes &&
                          freelancer_resumes.map((r, k) => (
                            <>
                              <div
                                className="d-flex mb-3 align-items-center"
                                key={k}
                              >
                                <ListItemIcon className="me-2">
                                  <CircleIcon
                                    style={{
                                      color: '#1843A0',
                                      width: '7px',
                                      height: '13px',
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography className="fs-14 text-gray font-gotham">
                                  {/* {r.freelancer_resume} */}
                                  <IconButton
                                    className="fs-15 ps-0"
                                    onClick={() => {
                                      GAEvents(FREELANCER_DETAIL_TITLE, "Download Resume", CLICKED, {
                                        userId: userData?.id || sessionStorage.getItem('random_id'),
                                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                        title: "Download"
                                      });
                                      saveAs(
                                        RESUME_BASE_URL + r?.freelancer_resume,
                                        r?.freelancer_resume
                                      );
                                    }}
                                  >
                                    {name ? name + '_Resume' : 'Resume'}
                                  </IconButton>
                                  <IconButton
                                    className="cat-edit-icon"
                                    onClick={() => {
                                      GAEvents(FREELANCER_DETAIL_TITLE, "Download Resume", CLICKED, {
                                        userId: userData?.id || sessionStorage.getItem('random_id'),
                                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                        title: "Download"
                                      });
                                      saveAs(
                                        RESUME_BASE_URL + r?.freelancer_resume,
                                        r?.freelancer_resume
                                      );
                                    }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Typography>
                              </div>
                            </>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {vendorId !== userId && (
                    <Box className="rating-review-card mt-4">
                      <Card className="card">
                        <CardContent className="p-0 d-flex justify-content-between mt-1">
                          <div className="d-flex flex-column">
                            <Typography
                              variant="body2"
                              className="fs-16 text-black fw-bold font-skmodernist mb-2"
                            >
                              Ratings / Reviews
                            </Typography>
                            <Box>
                              <Rating
                                name="read-only"
                                value={rating}
                                // onChange={(event, newValue) => {
                                //   setRatingValue(newValue);
                                // }}
                                precision={0.1}
                                size="small"
                                readOnly
                              />
                            </Box>
                          </div>

                          <Button
                            variant="contained"
                            className="submit-rating-btn"
                            onClick={() => {
                              GAEvents(FREELANCER_DETAIL_TITLE, "Submit Rating", CLICKED, {
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "Submit Rating"
                              });
                              if (userData?.registration?.id === undefined) {
                                handleOpenCallModal();
                              } else {
                                toggleFeedBackDrawer();
                              }
                            }}
                          >
                            Submit Rating
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {!currentProduct && (
                    <>
                      {' '}
                      <FormControl
                        sx={{ minWidth: 180 }}
                        size="small"
                        className="mb-3"
                      >
                        <FormControl
                          sx={{ minWidth: 180 }}
                          size="small"
                          className="mb-3"
                        >
                          <Select
                            // labelId="demo-select-small-label"
                            id="demo-select-small"
                            variant="outlined"
                            onChange={handleCategoryChange}
                            value={selectedCategoryId}
                          >
                            <MenuItem
                              onClick={() => {
                                GAEvents(FREELANCER_DETAIL_TITLE, "All Category", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: "All Category"
                                });
                                setSelectedCategoryId('All Category');
                                setAllProducts(products);
                              }}
                              key={55}
                              value={'All Category'}
                            >
                              All Category
                            </MenuItem>
                            {categories &&
                              categories.map((c) => (
                                <MenuItem
                                  onClick={() => {
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Select Category", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: c?.title
                                    });
                                    setSelectedCategoryId(c?.title);
                                    setAllProducts(
                                      products.filter(
                                        (p) => p?.category_id == c.id
                                      )
                                    );
                                  }}
                                  key={c.id}
                                  value={c?.title}
                                >
                                  {c?.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </FormControl>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                        className="mb-4"
                      >
                        {isTabletOrMobile && (
                          <>
                            {allproducts?.map((item) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  key={item.img}
                                  onClick={() => {
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Select Category", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: item?.title || ''
                                    });
                                    setCurrentProduct(item);
                                  }}
                                >
                                  <img
                                    src={`${PRODUCT_IMAGE_BASE_URL}${item.default_image}`}
                                    alt="product-image"
                                    loading="lazy"
                                    style={{
                                      marginBottom: '8px',
                                      width: '100%',
                                      height: '180px',
                                      borderRadius: '10px',
                                      // padding: '1em 1em 0 1em',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Typography className="fs-16 text-darkgrey font-skmodernist">
                                    {item.title}
                                  </Typography>

                                  <Typography className="fs-14 fw-bold text-secondary text-darkgrey">
                                    {item.budget}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </>
                        )}
                        {isDesktopOrLaptop && (
                          <>
                            {allproducts?.map((item) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  md={3}
                                  key={item.img}
                                  onClick={() => {
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Select Category", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: item?.title ||''
                                    });
                                    setCurrentProduct(item)}}
                                >
                                  <img
                                    src={`${PRODUCT_IMAGE_BASE_URL}${item.default_image}`}
                                    alt="product-image"
                                    loading="lazy"
                                    style={{
                                      marginBottom: '8px',
                                      width: '100%',
                                      height: '280px',
                                      borderRadius: '10px',
                                      // padding: '1em 1em 0 1em',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Typography className="fs-18 text-darkgrey font-skmodernist">
                                    {item.title}
                                  </Typography>

                                  <Typography className="fs-18 fw-bold text-secondary text-darkgrey">
                                    ₹ {item.budget}
                                  </Typography>
                                </Grid>
                              );
                            })}
                          </>
                        )}
                      </Grid>
                    </>
                  )}

                  {isTabletOrMobile && (
                    <>
                      {currentProduct && (
                        <Box>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginBottom: '20px',
                            }}
                          >
                            <ArrowBackIosIcon
                              onClick={() => {
                                GAEvents(FREELANCER_DETAIL_TITLE, "Product Close", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: 'Product Close'
                                });
                                setCurrentProduct(null);
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <img
                              src={`${PRODUCT_IMAGE_BASE_URL}${currentProduct.default_image}`}
                              alt="product-image"
                              loading="lazy"
                              onClick={() =>{
                                GAEvents(FREELANCER_DETAIL_TITLE, "Product Image", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: currentProduct?.title || ''
                                });
                                handleViewImageClick(
                                  currentProduct?.product_images
                                    ?.split(',')
                                    .map((i) => PRODUCT_IMAGE_BASE_URL + i)
                                )
                              }}
                              style={{
                                marginBottom: '8px',
                                width: '100%',
                                height: '220px',
                                borderRadius: '8px',
                                cursor: 'pointer',
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                          <Stack
                            direction="row"
                            spacing={1.5}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            useFlexGap
                            flexWrap="wrap"
                          >
                            <Typography className="fs-18 fw-bold font-skmodernist text-black mb-3">
                              {currentProduct?.title}
                            </Typography>
                            <Typography className="fs-14 fw-bold text-secondary text-darkgrey">
                              {findMinimumBudget(products) +
                              '/' +
                              currentProduct?.unit
                                ? currentProduct?.unit
                                : ''}
                            </Typography>
                          </Stack>
                          {currentProduct?.description && (
                            <Typography className="fs-14 font-gotham text-gray mb-3">
                              {currentProduct?.description}{' '}
                            </Typography>
                          )}
                          {currentProduct?.moq && (
                            <Typography className="fs-14 fw-bold text-darkgrey">
                              {'Min. Order Quantity: ' + currentProduct?.moq}
                            </Typography>
                          )}
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={()=>{
                              GAEvents(FREELANCER_DETAIL_TITLE, "Call / Connect", CLICKED, {
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: 'Call / Connect'
                              });
                              toggleCallBackDrawer();
                            }}
                            className="mt-3 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                            sx={{
                              borderRadius: '6px',
                              textTransform: 'capitalize',
                              height: '50px',
                            }}
                          >
                            Call / Connect
                          </Button>
                        </Box>
                      )}
                    </>
                  )}

                  {isDesktopOrLaptop && (
                    <>
                      {currentProduct && (
                        <Box
                          className="desktop-catalog-card"
                          sx={{
                            border: '1px solid #E3E3E3',
                            borderRadius: '8px',
                            padding: '20px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginBottom: '20px',
                            }}
                          >
                            <ArrowBackIosIcon
                              onClick={() => {
                                GAEvents(FREELANCER_DETAIL_TITLE, "Product Close", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: 'Product Close'
                                });
                                setCurrentProduct(null);
                              }}
                            />
                          </div>
                          <Box className="d-flex">
                            <div className="mb-3">
                              <img
                                src={`${PRODUCT_IMAGE_BASE_URL}${currentProduct.default_image}`}
                                alt="product-image"
                                loading="lazy"
                                onClick={() =>{
                                  GAEvents(FREELANCER_DETAIL_TITLE, "Product Image", CLICKED, {
                                    userId: userData?.id || sessionStorage.getItem('random_id'),
                                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                    title: currentProduct?.title || ''
                                  });
                                  handleViewImageClick(
                                    currentProduct?.product_images
                                      ?.split(',')
                                      .map((i) => PRODUCT_IMAGE_BASE_URL + i)
                                  )
                                }}
                                style={{
                                  width: '273px',
                                  height: '270px',
                                  borderRadius: '8px',
                                  marginRight: '26px',
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                            <Stack
                              direction="row"
                              spacing={1.5}
                              alignItems={'start'}
                              justifyContent={'space-between'}
                              useFlexGap
                              width={'100%'}
                            >
                              <Box style={{ width: '80%' }}>
                                <Typography className="fs-22 fw-bold font-skmodernist text-black mb-3">
                                  {currentProduct?.title}
                                </Typography>

                                {currentProduct?.description && (
                                  <Typography className="fs-15 font-gotham text-gray mb-3">
                                    {currentProduct?.description}{' '}
                                  </Typography>
                                )}

                                {currentProduct?.moq && (
                                  <Typography className="fs-14 fw-gotham text-black">
                                    {'Min. Order Quantity: ' +
                                      currentProduct?.moq}
                                  </Typography>
                                )}

                                <Typography className="fs-18 fw-bold text-secondary text-darkgrey">
                                  <span>₹ </span>
                                  {findMinimumBudget(products) + '/'}
                                  <span className="fs-13 font-skmodernist text-grey">
                                    {currentProduct?.unit
                                      ? currentProduct?.unit
                                      : ''}
                                  </span>
                                </Typography>
                              </Box>
                              <Box alignContent={'center'} height={'100%'}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  onClick={() => {
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Call / Connect", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: 'Call / Connect'
                                    });
                                    handleOpenModal();}}
                                  className="mt-3 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                                  sx={{
                                    borderRadius: '6px',
                                    textTransform: 'capitalize',
                                    height: '50px',
                                  }}
                                >
                                  Call / Connect
                                </Button>
                                <ConnectWithModal
                                  open={openModal}
                                  onClose={()=>{handleCloseModal();}}
                                  handleCall={()=>{
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Call / Connect", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: "Call Now",
                                      phone: user?.mobile_number ? '<mobile_no>' : ''
                                    });
                                    handleCall();
                                  }}
                                  handleWhatsappCall={()=>{
                                    GAEvents(FREELANCER_DETAIL_TITLE, "Call / Connect", CLICKED, {
                                      userId: userData?.id || sessionStorage.getItem('random_id'),
                                      reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                      title: "Send Requirement on WhatsApp",
                                      phone: whatsapp_number ? '<whatsapp_no>' : ''
                                    });
                                    handleWhatsappCall();}}
                                />
                              </Box>
                            </Stack>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column align-items-center">
                      {/* {feedbacks && feedbacks?.map((rating, index) => ( */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        {reviewStarsPercentages &&
                          Object.keys(reviewStarsPercentages)
                            .sort((a, b) => b - a)
                            .map((r) => {
                              return (
                                <div
                                  key={r}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    className="fs-15 font-skmodernist text-darkgrey me-1"
                                  >
                                    {r}
                                  </Typography>
                                  <StarIcon
                                    fontSize="small"
                                    sx={{ color: '#faaf00' }}
                                  />
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={reviewStarsPercentages[r]}
                                    style={{
                                      width: '150px',
                                      marginLeft: '10px',
                                    }}
                                  />
                                </div>
                              );
                            })}

                        {/* <LinearProgress variant="determinate" /> */}
                      </div>
                      {/* ))} */}
                    </div>
                    <Box className="text-end">
                      <Typography
                        variant="h5"
                        className="fs-40 fw-bold font-skmodernist"
                      >
                        {Number(rating).toFixed(1)}
                      </Typography>
                      <Rating
                        name="average-rating"
                        value={rating}
                        precision={0.1}
                        readOnly
                      />
                      <Typography
                        variant="subtitle1"
                        className="fs-14 font-skmodernist"
                      >
                        {reviews?.length} reviews
                      </Typography>
                    </Box>
                  </div>
                  <Divider
                    component="div"
                    className="mt-4 main-divider"
                    role="presentation"
                  />
                  {reviews &&
                    reviews?.map((f) => {
                      // console.log("====rating", f);
                      const duration = moment(f.createdAt).format(
                        'DD MMMM YYYY'
                      );
                      // const hours = duration.hours();
                      // const minutes = duration.minutes();
                      // const seconds = duration.seconds();

                      return (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            className="review-list"
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={f.reviewer_image_path || 'Profile Image'}
                                src={
                                  f.reviewer_image_path
                                    ? COMPANY_LOGO_BASE_URL +
                                      f.reviewer_image_path
                                    : require('../../assets/elynker_default_image.jpg')
                                }
                                className={classes.avatar}
                              />
                            </ListItemAvatar>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="subtitle1"
                                className="fs-16 text-darkgray font-skmodernist mb-1"
                              >
                                {f.reviewer_name}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                className="mb-1"
                              >
                                <Rating
                                  name="read-only"
                                  value={f?.review_star}
                                  readOnly
                                  size="small"
                                />
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  className="fs-14 font-skmodernist text-lightgray"
                                  style={{ marginLeft: '8px' }}
                                >
                                  {duration}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                className="fs-14 font-gotham text-gray"
                              >
                                {f.review}
                              </Typography>
                              {/* Add the following code to display the review image */}
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems={'center'}
                                justifyContent={'center'}
                                flexWrap="wrap"
                              >
                                {f?.image_path &&
                                  f.image_path
                                    .split(',')
                                    .map((imageUrl, index) => (
                                      <img
                                        onClick={() =>{
                                          GAEvents(FREELANCER_DETAIL_TITLE, "Review Image", CLICKED, {
                                            userId: userData?.id || sessionStorage.getItem('random_id'),
                                            reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                            title: 'Review Image'
                                          });
                                          handleViewImageClick(
                                            f?.image_path
                                              ?.split(',')
                                              .map(
                                                (i) => UPLOAD_REVIEW_IMAGE + i
                                              )
                                          )
                                        }}
                                        key={index}
                                        src={
                                          imageUrl.trim()
                                            ? UPLOAD_REVIEW_IMAGE +
                                              imageUrl.trim()
                                            : require('../../assets/elynker_default_image.jpg')
                                        }
                                        alt={`RvImg${index}`}
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          marginRight: '20px',
                                        }}
                                      />
                                    ))}
                              </Box>
                            </Box>
                          </ListItem>
                        </>
                      );
                    })}
                </TabPanel>
              </Box>

              <Drawer
                xs={12}
                anchor="bottom"
                open={isCallBackDrawerOpen}
                onClose={toggleCallBackDrawer}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-18 mb-2 fw-bold font-skmodernist "
                      gutterBottom
                    >
                      Connect with Mike
                    </Typography>
                    <CloseIcon onClick={()=>{
                      GAEvents(FREELANCER_DETAIL_TITLE, "Connect Close", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: 'Connect Close'
                      });
                      toggleCallBackDrawer();
                      }} />
                  </div>

                  <Typography className="fs-14 fw-normal font-gotham text-gray mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Proin felis a eget eget urna.{' '}
                  </Typography>

                  {/* <FormControlLabel
                                        className="d-flex align-items-center mb-4"
                                        control={
                                            <Checkbox
                                                id="customCheck1"
                                                className="py-0"
                                                sx={{
                                                    color: '#416AD4',
                                                    borderWidth: 1,
                                                    '& .MuiSvgIcon-root': {
                                                        borderWidth: 1,
                                                        borderRadius: 4,
                                                    },
                                                    '&.Mui-checked': {
                                                        color: '#416AD4',
                                                        borderRadius: 15,
                                                        borderWidth: 2,
                                                    },
                                                }}

                                            />
                                        }
                                        label={
                                            <Typography className="text-start fs-13 fw-normal font-gotham text-grey line-height">
                                                Terms & Conditions
                                            </Typography>
                                        }
                                    /> */}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={()=>{
                      GAEvents(FREELANCER_DETAIL_TITLE, "Call Connect", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Call Now",
                        phone: user?.mobile_number ? '<mobile_no>' : ''
                      });
                      handleCall();
                    }}
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Call Now
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    onClick={()=>{
                      GAEvents(FREELANCER_DETAIL_TITLE, "Call / Connect", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Send Requirement on WhatsApp",
                        phone: whatsapp_number ? '<whatsapp_no>' : ''
                      });
                      handleWhatsappCall();
                    }}
                    variant="contained"
                    className="mb-3 py-3 send-req-btn"
                  >
                    Send Requirement on WhatsApp
                  </Button>
                </div>
              </Drawer>

              <Drawer
                xs={12}
                anchor="bottom"
                open={isFeedBackDrawerOpen}
                onClose={()=>{
                  GAEvents(FREELANCER_DETAIL_TITLE, "Feedback", CLICKED, {
                    userId: userData?.id || sessionStorage.getItem('random_id'),
                    reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                    title: "Close"
                  });
                  toggleFeedBackDrawer();
                }}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-18 mb-2 fw-bold font-skmodernist "
                      gutterBottom
                    >
                      Feedback
                    </Typography>
                    <CloseIcon onClick={()=>{
                      GAEvents(FREELANCER_DETAIL_TITLE, "Feedback", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Close"
                      });
                      toggleFeedBackDrawer();
                      }} />
                  </div>

                  <div className="mb-4">
                    <Box className="text-center">
                      <Rating
                        name="read-only"
                        value={feedback?.feedbackRating}
                        onChange={(event, newValue) => {
                          addFeedback({ feedbackRating: newValue });
                          validateInput('feedbackRating', newValue);
                          // setRatingValue(newValue);
                        }}
                        size="large"
                      />
                    </Box>
                  </div>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors?.feedbackRating}
                  </FormHelperText>

                  {/* <div className="d-flex justify-content-evenly mb-4">
                                        <div className="d-flex flex-column align-items-center">
                                            <IconButton
                                                onClick={handleClick}
                                                className={`${classes.clickableEmoji} ${clicked ? 'active' : ''}`}
                                            >
                                                <BadIcon />
                                            </IconButton>
                                            <Typography className="fs-13 font-gotham text-grey">Bad</Typography>
                                        </div>

                                        <div className="d-flex flex-column align-items-center">
                                            <IconButton
                                                onClick={handleClick}
                                                className={`${classes.clickableEmoji} ${clicked ? 'active' : ''}`}
                                            >
                                                <AverageIcon />
                                            </IconButton>
                                            <Typography className="fs-13 font-gotham text-grey">Average</Typography>
                                        </div>

                                        <div className="d-flex flex-column align-items-center">
                                            <IconButton
                                                onClick={handleClick}
                                                className={`${classes.clickableEmoji} ${clicked ? 'active' : ''}`}
                                            >
                                                <GoodIcon />
                                            </IconButton>
                                            <Typography className="fs-13 font-gotham text-grey">Good</Typography>
                                        </div>
                                    </div> */}

                  {/* <div className="mb-3 feedback-chips">
                                        <Stack direction="row" spacing={1.5} useFlexGap flexWrap="wrap" justifyContent="center">
                                            <Chip
                                                label="Service"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Service")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Service") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Service") ? '#fff' : '#864FFD'
                                                }}
                                            /><Chip
                                                label="Quantity"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Quantity")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Quantity") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Quantity") ? '#fff' : '#864FFD'
                                                }}
                                            />
                                            <Chip
                                                label="Payment"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Payment")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Payment") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Payment") ? '#fff' : '#864FFD'
                                                }}
                                            />
                                            <Chip
                                                label="Promotion"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Promotion")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Promotion") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Promotion") ? '#fff' : '#864FFD'
                                                }}
                                            />
                                            <Chip
                                                label="Delivery"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Delivery")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Delivery") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Delivery") ? '#fff' : '#864FFD'
                                                }}
                                            />
                                            <Chip
                                                label="Gift"
                                                variant="outlined"
                                                onClick={() => handleChipClick("Gift")}
                                                style={{
                                                    backgroundColor: selectedChips.includes("Gift") ? '#864FFD' : '#fff',
                                                    color: selectedChips.includes("Gift") ? '#fff' : '#864FFD'
                                                }}
                                            />

                                        </Stack>
                                    </div> */}

                  <div className=" mb-3">
                    <InputLabel
                      htmlFor="inputCompanyName"
                      className="text-start fs-14 text-black font-skmodernist"
                    >
                      Care to Share more
                    </InputLabel>
                    <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                      <TextareaAutosize
                        id="inputComment"
                        placeholder="Type your feedback"
                        value={feedback?.feedbackDetail}
                        onChange={onChangeFeedbackDetails}
                        minRows={3} // You can adjust the number of rows as needed
                        maxRows={2} // You can adjust the number of rows as needed
                      />
                    </FormControl>
                    {/* <FormHelperText
                      sx={{ color: "red" }}
                      id="email-helper-text"
                    >
                      {errors.feedbackDetail}
                    </FormHelperText> */}
                  </div>

                  <div className="mb-3">
                    {feedback?.images === undefined ||
                    feedback?.images === null ||
                    feedback?.images?.length === 0 ? (
                      <Grid md={6} lg={6} xs={12} className=" mb-3">
                        <InputLabel
                          htmlFor="inputCompanyName"
                          className="text-start fs-14 text-black font-skmodernist"
                        >
                          Upload Image (Max 5){' '}
                          <span className="text-muted fs-12">
                            (JPEG or PNG files only)
                          </span>{' '}
                          {/* <span className="text-mandatory">*</span> */}
                        </InputLabel>

                        <input
                          style={{ display: 'none' }}
                          id="image"
                          ref={inputRef}
                          multiple
                          type="file"
                          accept="image/*"
                          onChange={changeHandler}
                        />

                        <Button
                          id="add_photo"
                          type="button"
                          fullWidth
                          variant="outlined"
                          name="add_photo"
                          value="add_photo"
                          className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                          onClick={()=>{
                            GAEvents(FREELANCER_DETAIL_TITLE, "Feedback", CLICKED, {
                              userId: userData?.id || sessionStorage.getItem('random_id'),
                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                              title: "Add Image"
                            });
                            handleImageClick();
                          }}
                          sx={{
                            borderRadius: '6px',
                            textTransform: 'capitalize',
                          }}
                        >
                          <img
                            src={AddBtnIcon}
                            alt="add-btn-icon"
                            className="user-logo"
                          />
                        </Button>
                      </Grid>
                    ) : (
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <InputLabel
                            htmlFor="inputproduct"
                            className="text-start fs-14 text-black font-skmodernist"
                          >
                            Upload Image (Max 5){' '}
                            <span className="text-muted fs-12">
                              (JPEG or PNG files only)
                            </span>{' '}
                            {/* <span className="text-mandatory">*</span> */}
                            <br />
                            <span className="text-muted fs-12">
                              (JPEG or PNG files only)
                            </span>
                          </InputLabel>
                          <Typography
                            variant="body1"
                            align="left"
                            className="fs-14 text-primary fw-normal font-gotham"
                            onClick={()=>{
                              GAEvents(FREELANCER_DETAIL_TITLE, "Feedback", CLICKED, {
                                userId: userData?.id || sessionStorage.getItem('random_id'),
                                reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                title: "View All"
                              });
                              toggleDrawer();
                            }}
                            gutterBottom
                          >
                            {`View All (${feedback.images.length})`}
                          </Typography>
                        </div>

                        <div className="upload-image-container my-1">
                          {feedback.images &&
                            Array.from(feedback.images)
                              ?.filter(
                                (img) =>
                                  img instanceof File ||
                                  (typeof img === 'object' &&
                                    Object.keys(img).length !== 0)
                              )
                              ?.slice(0, 3)
                              ?.map((img, imgIdx) => {
                                console.log('Images - ', img);
                                return (
                                  <div
                                    className="image-inner-field"
                                    key={imgIdx}
                                  >
                                    <img
                                      onClick={() =>{
                                        GAEvents(FREELANCER_DETAIL_TITLE, "Feedback Image", CLICKED, {
                                          userId: userData?.id || sessionStorage.getItem('random_id'),
                                          reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                          title: 'Feedback Image'
                                        });
                                        handleViewImageClick(
                                          typeof img === 'string'
                                            ? img
                                            : URL.createObjectURL(img)
                                        )
                                      }}
                                      src={
                                        typeof img === 'string'
                                          ? img
                                          : URL.createObjectURL(img)
                                      }
                                      alt="product-image"
                                      className="product-image"
                                    />
                                    <img
                                      src={ProductCloseIcon}
                                      alt="product-close-icon"
                                      className="product-close-icon"
                                      onClick={() => {
                                        GAEvents(FREELANCER_DETAIL_TITLE, "Delete Feedback Image", CLICKED, {
                                          userId: userData?.id || sessionStorage.getItem('random_id'),
                                          reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                          title: 'Delete Image'
                                        });
                                        // let newImg = [
                                        //   ...feedback.images,
                                        // ]?.splice(1, 1);
                                        // validateInput('photos', newImg);
                                        deleteProdImage(imgIdx);
                                      }}
                                    />
                                  </div>
                                );
                              })}

                          <div className="image-inner-field">
                            <input
                              style={{ display: 'none' }}
                              id="image"
                              ref={inputRef}
                              multiple
                              type="file"
                              accept="image/*"
                              onChange={changeHandler}
                            />
                            <Button
                              type="button"
                              fullWidth
                              variant="outlined"
                              name="add_more_photo"
                              value="add_more_photo"
                              className="add-button image-inner-field fs-15 font-skmodernist fw-bold"
                              sx={{
                                borderRadius: '6px',
                                textTransform: 'capitalize',
                              }}
                              onClick={()=>{
                                GAEvents(FREELANCER_DETAIL_TITLE, "Feedback Image", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: 'Add Image'
                                });
                                handleImageClick();
                              }}
                            >
                              <img
                                src={AddBtnIcon}
                                alt="add-btn-icon"
                                className="user-logo"
                              />
                            </Button>
                          </div>
                        </div>

                        <Drawer
                          xs={12}
                          anchor="bottom"
                          open={isDrawerOpen}
                          onClose={toggleDrawer}
                          sx={{
                            '& .MuiDrawer-paper': {
                              height: '90%',
                              borderTopLeftRadius: '20px',
                              borderTopRightRadius: '20px',
                            },
                          }}
                        >
                          <div className="p-3">
                            <div className="d-flex justify-content-between mb-3">
                              <Typography
                                variant="h5"
                                align="left"
                                alignItems="center"
                                className="fs-18 mb-2 fw-bold font-skmodernist "
                                gutterBottom
                              >
                                Photos
                              </Typography>
                              <CloseIcon onClick={()=>{
                                GAEvents(FREELANCER_DETAIL_TITLE, "Feedback Photo", CLICKED, {
                                  userId: userData?.id || sessionStorage.getItem('random_id'),
                                  reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                  title: 'Feedback Photo Close'
                                });
                                toggleDrawer();}} />
                            </div>
                            <div className="product-grid mt-3">
                              {feedback.images &&
                                Array.from(feedback.images)
                                  ?.filter(
                                    (img) =>
                                      img instanceof File ||
                                      (typeof img === 'object' &&
                                        Object.keys(img).length !== 0)
                                  )
                                  ?.map((img, imgIdx) => {
                                    console.log('===img viewer feedback', img);
                                    return (
                                      <div
                                        className="image-inner-field"
                                        key={imgIdx}
                                      >
                                        <img
                                          alt="product-image"
                                          onClick={() => {
                                            GAEvents(FREELANCER_DETAIL_TITLE, "Feedback Photo", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: 'Image View'
                                            });
                                            handleViewImageClick(
                                              typeof img === 'string'
                                                ? img
                                                : URL.createObjectURL(img)
                                            )
                                          }}
                                          className="product-image"
                                          src={
                                            typeof img === 'string'
                                              ? img
                                              : URL.createObjectURL(img)
                                          }
                                        />
                                        <img
                                          src={ProductCloseIcon}
                                          alt="product-close-icon"
                                          className="product-close-icon"
                                          onClick={() => {
                                            GAEvents(FREELANCER_DETAIL_TITLE, "Feedback Photo", CLICKED, {
                                              userId: userData?.id || sessionStorage.getItem('random_id'),
                                              reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                                              title: 'Image Delete'
                                            });
                                            // let newImg = [
                                            //   ...feedback.images,
                                            // ]?.splice(1, 1);
                                            // validateInput('photos', newImg);
                                            deleteProdImage(imgIdx);
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Drawer>
                      </div>
                    )}
                    {/* <FormHelperText
                      sx={{ color: "red" }}
                      id="email-helper-text"
                    >
                      {errors.photos}
                    </FormHelperText> */}
                  </div>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={()=>{
                      GAEvents(FREELANCER_DETAIL_TITLE, "Submit", CLICKED, {
                        userId: userData?.id || sessionStorage.getItem('random_id'),
                        reg_uuid: userData?.registration?.uuid || sessionStorage.getItem('random_id'),
                        title: "Submit"
                      });
                      onFeedbackSubmit();
                    }}
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Submit
                  </Button>
                </div>
              </Drawer>
            </form>
          </Grid>
        </Grid>
        <ImageDialog
          open={viewImagedialogOpen}
          onClose={handleViewImageCloseDialog}
          image={selectedImage}
        />
      </Container>
    </ThemeProvider>
  );
};

FreelancerDetailForm.propTypes = {
  userData: PropTypes.object,
  flDetails: PropTypes.object,
  vendorsDetails: PropTypes.object,
  cover_image: PropTypes.string,
  company_name: PropTypes.string,
  about_company: PropTypes.string,
  created_by: PropTypes.string,
  products: PropTypes.array,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address: PropTypes.string,
  social_links: PropTypes.array,
  whatsapp_number: PropTypes.string,
  contacted_members: PropTypes.string,
  reviews: PropTypes.array,
  image_path: PropTypes.string,
  postMemberContacted: PropTypes.func,
  id: PropTypes.string,
  categories: PropTypes.array,
  pushMessage: PropTypes.func,
  b2bDetails: PropTypes.object,
  addFeedback: PropTypes.func,
  removeFeedbackImages: PropTypes.func,
  saveFeedback: PropTypes.func,
  getFeedback: PropTypes.func,
  additional_detail1: PropTypes.string,
  additional_detail2: PropTypes.string,
  additional_detail3: PropTypes.string,
  rating: PropTypes.string,
  updateVendorUserData: PropTypes.func,
  rating_member_count: PropTypes.number,
  certificates: PropTypes.array,
  freelancer_resumes: PropTypes.array,
  freelancer_banner_projects: PropTypes.array,
  freelancer_skills: PropTypes.array,
  language: PropTypes.string,
  reviewStarsPercentages: PropTypes.number,
  localFlDetails: PropTypes.object,
  user: PropTypes.object,
  name: PropTypes.string,
  freelancer_role: PropTypes.string,
  clearFeedBackFormData: PropTypes.func,
  vendorId: PropTypes.string,
  userId: PropTypes.string,
  getFreelancerVendorDetails: PropTypes.func,
  getVendorDetails: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { flDetails } = state.b2bVendorlisting;
  const localFlDetails = state.b2bDetails;
  const { vendorsDetails } = state.b2bVendorlisting;
  const vendorId = vendorsDetails?.userId;
  const userId = userData?.user?.id;
  console.log('===mapStateToProps reviewStarsPercentages', vendorsDetails);
  const {
    id = null,
    cover_image = '',
    categories = [],
    additional_detail1 = '',
    additional_detail2 = '',
    additional_detail3 = '',
    rating = '',
    freelancer_skills = null,
    freelancer_banner_projects = [],
    freelancer_resumes = [],
    language,
    certificates = [],
    user,
    name = '',
    freelancer_role = '',
    rating_member_count = 0,
    company_name = '',
    product_count = 0,
    about_company = '',
    created_by = '',
    products = [],
    address1 = '',
    address2 = '',
    social_links = [],
    image_path = '',
    whatsapp_number = '',
    contacted_members = 0,
    reviews = [],
    reviewStarsPercentages = null,
  } = vendorsDetails;
  return {
    userData,
    flDetails,
    vendorsDetails,
    cover_image,
    company_name,
    about_company,
    created_by,
    products,
    social_links,
    address1,
    address2,
    whatsapp_number,
    reviews,
    freelancer_banner_projects,
    freelancer_resumes,
    certificates,
    contacted_members,
    image_path,
    id,
    freelancer_skills,
    rating_member_count,
    rating,
    categories,
    additional_detail1,
    additional_detail2,
    product_count,
    additional_detail3,
    language,
    reviewStarsPercentages,
    localFlDetails,
    user,
    name,
    freelancer_role,
    vendorId,
    userId,
  };
};

export default connect(mapStateToProps, {
  postMemberContacted,
  pushMessage,
  saveFeedback,
  updateVendorUserData,
  addFeedback,
  clearFeedBackFormData,
  removeFeedbackImages,
  getFreelancerVendorDetails,
  getVendorDetails,
})(FreelancerDetailForm);
